
import { ExaminerResourcePlanningChartModel } from "@/api-client/modal";
import { init } from "echarts";
import { defineComponent, onMounted } from "vue";
import { setup } from "vue-class-component";

export default defineComponent({
  props: {
    chartName: String,
    chartData: ExaminerResourcePlanningChartModel,
  },
  setup(props) {
   onMounted(()=>{
    initLineChart();
   })
    

    function initLineChart() {
      // @ts-ignore
      var myChart = init(
        document.getElementById(`${props.chartName}-chart-content`)!
      );
      var option: any = {
        // 悬浮框
        tooltip: {
          show: false,
        },
        xAxis: {
          data: props.chartData!.xData,
        },
        yAxis: {
          minInterval: 1,
        },
        series: [
          {
            type: "bar",
            label: {
              show: true,
              position: "top",
            },
            data: props.chartData!.yData,
            barWidth: 15,
            itemStyle: {
              color: "rgba(91,143,249,0.85)",
            },
            markLine: {
              symbol: "none",
              data: !!props.chartData!.yData && props.chartData!.yData.length !== 0 ? [
                {
                  // 鼠标悬停事件  true没有，false有
                  silent: false,
                  lineStyle: {
                    // 警戒线的样式  ，虚实  颜色
                    type: "solid",
                    color: "#F5A623",
                  },
                  label: {
                    position: "end",
                    formatter: props.chartData!.moreCount,
                  },
                  // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                  yAxis: props.chartData!.moreCount,
                },
              ] : [],
            },
          },
        ],
      };
      myChart.setOption(option);
    }
    return{
      initLineChart
    }
  },
});
